import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useToast, Flex, Box, FormControl, FormLabel, Input, Button, useColorModeValue, Text, Link } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const auth = getAuth();
  const toast = useToast();
  const navigate = useNavigate();

  const bgColor = useColorModeValue('white', 'gray.700');
  const colorScheme = 'blue'; // Keep consistent with SignIn

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      toast({
        title: 'Account created.',
        description: "You've successfully signed up! You are now logged in!",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      navigate('/'); // Redirect to home/dashboard
    } catch (error) {
      toast({
        title: 'Error signing up.',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Sign Up - Your App Name</title>
        <meta name="description" content="Create an account to start managing your finances effectively with our free and easy-to-use budgeting tools." />
      </Helmet>
      <Flex justifyContent="center" alignItems="center" h="100vh" flexDirection="column">
        <Text fontSize="3xl" fontWeight="bold" mb={4}>Create Your Account</Text>
        <Box width="400px" p={6} boxShadow="lg" borderRadius="lg" bg={bgColor}>
          <form onSubmit={handleSignUp}>
            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} focusBorderColor={`${colorScheme}.500`} />
            </FormControl>
            <FormControl isRequired mt={4}>
              <FormLabel>Password</FormLabel>
              <Input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} focusBorderColor={`${colorScheme}.500`} />
            </FormControl>
            <Button width="full" mt={4} type="submit" colorScheme={colorScheme}>
              Sign Up
            </Button>
          </form>
        </Box>
        <Text mt={6}>Already have an account? <Link color={`${colorScheme}.500`} href="/signin">Sign In</Link></Text>
        <Text mt={4} textAlign="center" fontSize="sm">Join us and start saving and managing your finances today with our user-friendly budgeting app. It's fast, free, and secure.</Text>
      </Flex>
    </>
  );
};

export default SignUp;
