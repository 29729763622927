import React from 'react';
import { Box, ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar'; // Adjust path as necessary
import Footer from './components/Footer'; // Import the Footer component
import SignIn from './components/SignIn'; // Adjust path as necessary
import Home from './components/Home'; // Adjust path as necessary
import SignUp from './components/SignUp'; // Adjust path as necessary
import { AuthProvider } from './components/AuthProvider'; // Adjust path
import ProtectedRoute from './components/ProtectedRoute'; // Adjust path

// Define your custom theme
const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: 'sans-serif',
        bg: 'gray.100',
        color: 'gray.800',
      },
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <Box pt="12rem" minH="100vh" pb="12rem"> {/* Add padding-bottom to ensure content does not overlap the footer */}
          <Navbar />
          <Routes>
            <Route path="/" element={<Navigate replace to="/home" />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/home" element={<Home />} />
              {/* Define more protected routes as needed */}
            </Route>
          </Routes>
          <Footer /> {/* Include the Footer here */}
        </Box>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
