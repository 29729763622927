import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Box, Image, VStack, Heading, Text, Button, Stack, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Link
} from '@chakra-ui/react';

const FAQAndAppInfo = () => {
  const faqs = [
    {
      question: "How do I set up my monthly budget?",
      answer: "Setting up your monthly budget is easy. Simply enter your total budget for the month in the 'Monthly Budget' field, and our app will handle the rest."
    }
    // Add more FAQ items here
  ];

  return (
    <>
      <Helmet>
        <title>How to Budget 101 - Your App Name</title>
        <meta name="description" content="Learn how to manage your budget effectively with our app. Discover tips and tools for budgeting success." />
        <meta name="keywords" content="budgeting, financial planning, money management" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": faqs.map(faq => ({
              "@type": "Question",
              "name": faq.question,
              "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answer
              }
            }))
          })}
        </script>
      </Helmet>

      <Box my={10} px={{ base: 4, md: 8 }} width="full" maxW="xl" mx="auto">
        <Box
          direction={{ base: 'column', sm: 'row' }}
          overflow='hidden'
          border='1px'
          borderColor='gray.200'
          borderRadius='lg'
          display='flex'
          alignItems={{ base: 'center', sm: 'flex-start' }}
        >
          <Image
            objectFit='cover'
            w={{ base: '100%', sm: '200px' }}
            h={{ sm: '200px' }}
            src='https://images.unsplash.com/photo-1554252116-ee59370d1f66?q=80&w=3869&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
            alt='Caffe Latte'
            flexShrink={0}
          />

          <Stack spacing={4} p={5} width={{ base: '100%', sm: 'auto' }} flex={1}>
            <Heading size='md'>How to budget 101</Heading>
            <Text py='2'>
              A budget is a plan for every dollar you have.
            </Text>
            <Link href="https://www.nerdwallet.com/article/finance/how-to-budget" isExternal>
              <Button variant='solid' colorScheme='blue'>
                Read More
              </Button>
            </Link>
          </Stack>
        </Box>

        <VStack spacing={5} mt={10}>
          <Text fontSize={{ base: "xl", md: "2xl" }} fontWeight="bold">How Our App Works</Text>
          <Text textAlign="justify">
            Our app simplifies budget management for professionals, students, and anyone looking to gain financial clarity. Start by setting a monthly budget, then track your expenses to see where your money goes. Our app automatically calculates your remaining budget and daily allowance, helping you make informed financial decisions.
          </Text>

          <Text fontSize={{ base: "xl", md: "2xl" }} fontWeight="bold" pt={5}>Frequently Asked Questions</Text>
          <Accordion allowMultiple width="full">
            {faqs.map((faq, index) => (
              <AccordionItem key={index}>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    {faq.question}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {faq.answer}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </VStack>
      </Box>
    </>
  );
};

export default FAQAndAppInfo;
