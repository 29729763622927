import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Input, FormControl, FormLabel, useToast, Flex, useColorModeValue, Text, Link } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase';
import FAQAndAppInfo from './FAQAndAppInfo'; // Add this line

export const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const toast = useToast();
  const navigate = useNavigate();

  const bgColor = useColorModeValue('white', 'gray.700');
  const colorScheme = 'blue';

  const handleSignIn = async (e) => {
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/home');
      toast({
        title: 'Success',
        description: 'You are now signed in.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error signing in',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Sign In to Budget Better with [Your App Name] - Manage Your Finances Effortlessly</title>
        <meta name="description" content="Unlock the full potential of your finances with our Free Budget App. Sign in now to manage your budget, track expenses, and save smartly." />
      </Helmet>
      <Flex justifyContent="center" alignItems="center" h="100vh" flexDirection="column">
        <Text fontSize="3xl" fontWeight="bold" mb={4}>Sign In to Your Account</Text>
        <Box width="400px" p={6} boxShadow="lg" borderRadius="lg" bg={bgColor}>
          <form onSubmit={handleSignIn}>
            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input type="email" placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)} focusBorderColor={`${colorScheme}.500`} />
            </FormControl>
            <FormControl isRequired mt={4}>
              <FormLabel>Password</FormLabel>
              <Input type="password" placeholder="Enter your password" onChange={(e) => setPassword(e.target.value)} focusBorderColor={`${colorScheme}.500`} />
            </FormControl>
            <Button width="full" mt={4} type="submit" colorScheme={colorScheme}>
              Sign In
            </Button>
          </form>
        </Box>
        <Text mt={6}>Don't have an account? <Link color={`${colorScheme}.500`} href="/signup">Sign Up</Link></Text>
        <FAQAndAppInfo /> 
      </Flex>
      
    </>
  );
};

export default SignIn;
