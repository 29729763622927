import React from 'react';
import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import { useAuth } from './AuthProvider'; // Adjust this import according to your file structure

const BudgetAppInfo = () => {
  const { currentUser } = useAuth();
  
  return (
    <Box p={5}>
      <VStack spacing={4} align="stretch">
        <Text fontSize="lg" fontWeight="bold">
          Welcome, {currentUser?.email}
        </Text>
        <Heading as="h2" size="lg">Unlock Free Budgeting with Ease</Heading>
        <Text>
          Navigate your finances with our Free Budget App – a straightforward solution for anyone seeking financial clarity. Designed for professionals, students, and anyone in between, our app simplifies budget management.
        </Text>
        <Text>
          Experience effortless budget setup, expense tracking, and spending categorization. Our user-friendly app aids in making wise financial choices, steering your habits towards your goals.
        </Text>
        <Text>
          Our app's hallmark is its simplicity and accessibility. Compatible with iOS and Android, it allows for seamless financial oversight anywhere. Stay updated with real-time alerts and budget insights.
        </Text>
        <Text>
          Transform your financial habits alongside thousands of satisfied users. Embrace financial freedom with our app - every penny counts towards your success.
        </Text>
        <Text>
          Begin your path to a financially organized life. With our app, embrace smarter budgeting, prudent spending, and savings for your aspirations.
        </Text>
      </VStack>
    </Box>
  );
};

export default BudgetAppInfo;
