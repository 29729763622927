import React from 'react';
import { Flex, Link, useColorModeValue, Text, Box } from '@chakra-ui/react';

export const Footer = () => {
  const bgColor = useColorModeValue('blue.500', 'blue.900');
  const textColor = useColorModeValue('white', 'gray.200');

  return (
    <Flex
      px={4}
      py={{ base: 4, md: 3 }}
      direction={{ base: 'column', md: 'row' }}
      align="center"
      justify="space-between"
      bg={bgColor}
      color={textColor}
      position="fixed"
      bottom="0"
      left="0"
      right="0"
      textAlign={{ base: 'center', md: 'left' }}
      zIndex="banner"
    >
      <Text mb={{ base: 4, md: 0 }}>© {new Date().getFullYear()} - Developed by 
        <Link
          href="https://orhanbiler.us"
          isExternal
          color="teal.200" // or use textColor if you prefer the same color as the rest of the text
          _hover={{ textDecoration: 'underline' }}
        >
          {' '}O. Biler
        </Link>
      </Text>
      <Box>
        {/* Other content if necessary */}
      </Box>
    </Flex>
  );
};

export default Footer;
