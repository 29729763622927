import React from 'react';
import { Box, Flex, Spacer, Link, useToast, useColorModeValue, Image } from '@chakra-ui/react';
import { useAuth } from './AuthProvider';
import { signOut } from 'firebase/auth';
import { auth } from './firebase';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

export const Navbar = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
      toast({
        title: 'Logged out',
        description: 'You have successfully logged out.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Logout Error:", error);
      toast({
        title: 'Logout failed',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Theme improvements
  const bgColor = useColorModeValue('blue.500', 'blue.900');
  const textColor = useColorModeValue('white', 'gray.200');

  return (
    <Flex
      px={4}
      py={3}
      boxShadow="md"
      align="center"
      bg={bgColor}
      color={textColor}
      position="fixed"
      top="0"
      left="0"
      right="0"
      zIndex="banner"
    >
      <Box p="2" display="flex" alignItems="center" position="relative">
        <Image src="/images/budgetlogo.png" alt="Budget App Logo" boxSize="11rem" position="absolute" left={-5} top="-4rem" zIndex="banner" />
        <Box pl="8rem"> {/* Adjust this padding to ensure the text does not overlap the image */}
          <Link as={RouterLink} to="/" _hover={{ textDecoration: 'none' }}>
            <strong style={{ color: textColor, cursor: 'pointer' }}>Budget App</strong>
          </Link>
        </Box>
      </Box>
      <Spacer />
      <Flex>
        {!currentUser && (
          <>
            <Link as={RouterLink} to="/signup" mr={2} color={textColor} _hover={{ textDecoration: 'underline' }}>
              Sign Up
            </Link>
            <Link as={RouterLink} to="/signin" mr={2} color={textColor} _hover={{ textDecoration: 'underline' }}>
              Sign In
            </Link>
          </>
        )}
        {currentUser && (
          <Link as={RouterLink} to="/" mr={4} color={textColor} _hover={{ textDecoration: 'underline' }} onClick={handleLogout}>
            Logout
          </Link>
        )}
      </Flex>
    </Flex>
  );
};

export default Navbar;
