import React, { useState, useEffect } from 'react';
import { Box, FormControl, FormLabel, Input, Button, Text, VStack, List, ListItem, Tooltip, InputGroup, InputLeftAddon, Icon, useColorModeValue } from '@chakra-ui/react';
import { AddIcon, InfoOutlineIcon, CloseIcon } from '@chakra-ui/icons';
import { db } from './firebase';
import { collection, addDoc, query, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import { useAuth } from './AuthProvider';
import BudgetAppInfo from './BudgetAppInfo';



export const ExpenseTracker = () => {
  const [monthlyBudget, setMonthlyBudget] = useState(0);
  const [expenses, setExpenses] = useState([]);
  const [expenseName, setExpenseName] = useState('');
  const [expenseAmount, setExpenseAmount] = useState('');
  const [remainingBudget, setRemainingBudget] = useState(0);
  const [dailyAllowance, setDailyAllowance] = useState(0);
  const { currentUser } = useAuth();

// Define a cohesive color scheme
const bgColor = useColorModeValue('gray.50', 'gray.800');
const inputAddonBg = useColorModeValue('gray.200', 'gray.700');
const buttonColorScheme = 'blue'; // Use Chakra UI's color scheme for consistency
const listItemBg = useColorModeValue('gray.100', 'gray.700');
const listItemHoverBg = useColorModeValue('gray.100', 'gray.600');
const textColor = useColorModeValue('gray.800', 'gray.50');
const borderRadius = "lg"


  useEffect(() => {
    if (!currentUser) return;
    const expensesRef = collection(db, "users", currentUser.uid, "expenses");
    const q = query(expensesRef);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const expensesArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setExpenses(expensesArray);
    });

    return () => unsubscribe();
  }, [currentUser]);

  useEffect(() => {
    const totalExpenses = expenses.reduce((acc, expense) => acc + expense.amount, 0);
    const remaining = monthlyBudget - totalExpenses;
    setRemainingBudget(remaining);
  
    const today = new Date();
    const daysInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
    const daysLeftInMonth = daysInMonth - today.getDate();
    setDailyAllowance(remaining / daysLeftInMonth);
  }, [monthlyBudget, expenses]);

  const handleMonthlyBudgetChange = (e) => {
    setMonthlyBudget(parseFloat(e.target.value) || 0);
  };

  const handleExpenseAmountChange = (e) => {
    setExpenseAmount(parseFloat(e.target.value) || '');
  };

  const addExpense = async () => {
    if (!expenseName || expenseAmount === '' || !currentUser) return;
    const expenseRef = collection(db, "users", currentUser.uid, "expenses");
    const newExpense = { name: expenseName, amount: parseFloat(expenseAmount), createdAt: new Date() };
    await addDoc(expenseRef, newExpense);
    setExpenseName('');
    setExpenseAmount('');
  };

  const deleteExpense = async (expenseId) => {
    if (!currentUser || !expenseId) return;
    const expenseRef = doc(db, "users", currentUser.uid, "expenses", expenseId);
    await deleteDoc(expenseRef);
  };

  return (
<Box p={8} shadow="lg" borderWidth="1px" maxW="3xl" mx="auto" bg={bgColor} my={6} borderRadius={borderRadius}>
      <VStack spacing={4}>
      <FormControl>
  <FormLabel htmlFor="monthly-budget">Monthly Budget</FormLabel>
  <InputGroup>
    <InputLeftAddon children="$" bg={inputAddonBg} _hover={{ bg: 'blue.200' }} />
    <Input
      id="monthly-budget"
      type="number"
      placeholder="e.g., 4000"
      value={monthlyBudget}
      onChange={handleMonthlyBudgetChange}
      min="0"
      focusBorderColor="blue.500"
      _hover={{ borderColor: 'blue.500' }}
    />
  </InputGroup>
</FormControl>
              
                      <FormControl>
                        <FormLabel htmlFor="expense-name">Expense Name</FormLabel>
                        <Input
                          id="expense-name"
                          type="text"
                          value={expenseName}
                          onChange={e => setExpenseName(e.target.value)}
                          placeholder="e.g., Groceries"
                          _hover={{ borderColor: 'blue.500' }}

                        />
                      </FormControl>
              
                      <FormControl>
  <FormLabel htmlFor="expense-amount">Expense Amount</FormLabel>
  <InputGroup>
    <InputLeftAddon children="$" bg={inputAddonBg} _hover={{ bg: 'blue.200' }} />
    <Input
      id="expense-amount"
      type="number"
      value={expenseAmount}
      onChange={handleExpenseAmountChange}
      min="0"
      placeholder="e.g., 150"
      focusBorderColor="blue.500"
      _hover={{ borderColor: 'blue.500' }}
    />
  </InputGroup>
</FormControl>
              
                      <Button
  leftIcon={<AddIcon />}
  onClick={addExpense}
  colorScheme={buttonColorScheme} // Use this for consistent styling
  variant="solid">
  Add Expense
</Button>
                      <Text>Remaining Budget: ${remainingBudget.toFixed(2)}</Text>
                      <Text>Daily Allowance: ${dailyAllowance.toFixed(2)}
                        <Tooltip label="The daily allowance value is calculated based on the remaining budget and the number of days left in the current month." placement="top">
                          <span><InfoOutlineIcon ml={2} /></span>
                        </Tooltip>
                      </Text>
                    </VStack>
                    <Text mt={4}>Expenses:</Text>
                    <List spacing={3}>
                      {expenses.map(expense => (
                        <ListItem key={expense.id} display="flex" justifyContent="space-between" alignItems="center" bg={listItemBg} p={3} borderRadius="md" _hover={{ bg: listItemHoverBg }}>
                          <Text color={textColor}>{expense.name}: ${expense.amount}</Text>
                          <Icon as={CloseIcon} w={4} h={4} cursor="pointer" color="red.500" onClick={() => deleteExpense(expense.id)} />
                        </ListItem>
                      ))}
                    </List>
                  <br></br>
                    <BudgetAppInfo />
                  </Box>
                 
                );
              };
              