import React from 'react';
import { ExpenseTracker } from './ExpenseTracker'; // Adjust the path as necessary
import { Center } from '@chakra-ui/react';

export const Home = () => {
  return (
    <Center minH="100vh"> {/* Ensure full viewport height and center content vertically and horizontally */}
      <ExpenseTracker /> {/* Include the ExpenseTracker component */}
    </Center>
  );
};

export default Home; // This line makes it a default export
